@font-face {
  font-family: "Montserrat";
  font-weight: normal;
  src: url("../fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat";
  font-weight: bold;
  src: url("../fonts/Montserrat-Bold.ttf");
}

// @font-face {
//   font-family: "Montserrat";
//   font-weight: 500;
//   src: url("../fonts/Montserrat-SemiBold.ttf");
// }

@font-face {
  font-family: "Montserrat";
  font-weight: 300;
  src: url("../fonts/Montserrat-Light.ttf");
}