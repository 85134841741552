.main {
    position: relative;
    margin: 0 25px;
}

.label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.header {
    font-style: normal;
    font-weight: bold;
    font-size: 120px;
    line-height: 120px;
    padding-bottom: 8px;
}

.small_header {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
}

.text {
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    color: #0A0042;
    text-align: center;
    margin-left: -15px;
}