.main {
  width: 100%;
  height: 250px;
  padding: 10px 25px 0;
  color: #fff;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.column {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 49px;
}

@media (max-width: 1100px) {
  .title {
    font-size: 32px;
    line-height: 40px;
  }
}