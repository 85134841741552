.main {
    position: relative;
    width: 540px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.header {
    font-style: normal;
    font-weight: bold;
    font-size: 140px;
    line-height: 160px;
}

.list_item {
    vertical-align: middle;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    margin: 0;
    padding-bottom: 5%;
    div {
        position: relative;
        height: 50px;
        width: 100px;
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
        }
    }
    span {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 32px;
        color: #0A0042;
        text-align: center;
    }
}