// @import './variables.scss';
@import './fonts.scss';

div, span {
  box-sizing: border-box;
  font-family: Montserrat;
}

body {
  margin: 0;
  padding: 0;
}