
.main {
  width: 100%;
  padding: 40px 120px 0px;
  color: #fff;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
}

.column {
  width: 40%;
  margin-right: 100px;
}

.address {
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 49px;
  margin-top: 16px;
}

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 42px;
  margin-bottom: 19px;
}

.text {
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 46px;
}

@media (max-height: 1000px) {

  .main {
    width: 100%;
    padding: 25px 100px 0px;
  }
  
  .column {
    width: 40%;
    margin-right: 80px;
  
    img {
      height: 90px;
    }
  }
  
  .address {
    font-size: 32px;
    line-height: 40px;
    margin-top: 10px;
  }

  .title {
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 10px;
  }
  
  .text {
    font-size: 26px;
    line-height: 36px;
  }
}