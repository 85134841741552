
.wrapper {
  position: relative;
  width: 100%;
  height: 68%;
}

.main {
  width: 1366px;
  height: 715px;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center center;
  padding: 0 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}